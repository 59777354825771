import request from './request'
import {uuid} from 'vue-uuid'

const Payee = {
  getPayee() {
    return request({
      url: `/v1/payee`,
      method: "get",
    });
  },
  search(name) {
    return request({
      url: `/v1/payee/search?search_name=${name}`,
      method: "get",
    });
  },
  getByCurrency(currency, type) {
    const typeParam =
      ["gbp", "eur"].includes(currency.toLowerCase()) === false
        ? `swt/${type}`
        : `${currency.toLowerCase()}`;
    return request({
      url: `/v1/payee/${typeParam}`,
      method: "get",
    });
  },
  getEdit(currency, type, uid) {
    const typeParam =
      ["gbp", "eur"].includes(currency.toLowerCase()) === false
        ? `swt/${type}/${uid}`
        : `${currency.toLowerCase()}/${uid}`;
    return request({
      url: `/v1/payee/${typeParam}`,
      method: "get",
    });
  },
  postAdd(currency, type, data) {
    const typeParam =
      ["gbp", "eur"].includes(currency.toLowerCase()) === false
        ? `swt/${type}`
        : `${currency.toLowerCase()}`;
    data.idempotency = uuid.v1();
    return request({
      url: `/v1/payee/${typeParam}`,
      method: "post",
      data: data,
    });
  },
  putEdit(currency, type, uid, data) {
    const typeParam =
      ["gbp", "eur"].includes(currency.toLowerCase()) === false
        ? `swt/${type}/${uid}`
        : `${currency.toLowerCase()}/${uid}`;
    data.idempotency = uuid.v1();
    return request({
      url: `/v1/payee/${typeParam}`,
      method: "put",
      data: data,
    });
  },
  delPayee(currency, type, uid) {
    const typeParam =
      ["gbp", "eur"].includes(currency.toLowerCase()) === false
        ? `swt/${type}/${uid}`
        : `${currency.toLowerCase()}/${uid}`;
    return request({
      url: `/v1/payee/${typeParam}`,
      method: "delete",
      data: { idempotency: uuid.v1() },
    });
  },
  postGbpCheck(data) {
    data.idempotency = uuid.v1();
    return request({
      url: `/v1/payee/gbp/check`,
      method: "post",
      data: data,
    });
  },

  checkBeneControl() {
    return request({
      url: `/v1/payee/control-firewall`,
      method: "get",
    });
  },

  getBankList(currency = "") {
    return request({
      url: `/v1/payee/bankname/${currency}`,
      method: "get",
    });
  },

  getPayeeInstant(uid){
    return request({
      url: `/v1/payee/instant/${uid}`,
      method: "get",
    });
  },
  getPayeeInstantList(){
    return request({
      url: `/v1/payee/instant`,
      method: "get",
    });
  },
  postPayeeInstant(data){
    return request({
      url: `/v1/payee/instant`,
      method: "post",
      data: data
    });
  },
  delPayeeInstant(uid){
    return request({
      url: `/v1/payee/instant/${uid}`,
      method: "delete",
    });
  },
};

export default Payee 